import { ref } from "vue";

export function customPlayerFunctions(fetchUserData, loadNextPage) {
  const isMuted = ref([]);
  const isDragging = ref(false);
  const isFullscreen = ref([]); // Controle de full-screen para cada vídeo
  let draggingIndex = null;
  let wasPlaying = false;
  const touchStartX = ref(null); // Posição inicial do toque
  const touchEndX = ref(null); // Posição final do toque  
  

  

  // Função para alternar o mute de um vídeo
  const toggleMute = (index) => {
    const videoElements = document.querySelectorAll(".responsive-video.preview");
    const videoEl = videoElements[index];

    if (videoEl) {
      videoEl.muted = !videoEl.muted;
      isMuted.value[index] = videoEl.muted;
    }
  };

  // Atualiza a posição da barra de progresso com base no tempo atual do vídeo
  const updateProgress = (index) => {
    const videoEl = document.querySelectorAll('.responsive-video.preview')[index];
    const handle = document.querySelectorAll('.custom-progress-handle')[index];
    if (videoEl && handle) {
      handle.style.left = `${(videoEl.currentTime / videoEl.duration) * 100}%`;
    }
  };

  // Ajusta o tempo do vídeo com base na posição clicada na barra de progresso
  const seekVideo = (event, index) => {
    const videoEl = document.querySelectorAll('.responsive-video.preview')[index];
    const progressBar = event.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    videoEl.currentTime = ((event.clientX - rect.left) / rect.width) * videoEl.duration;
  };

  // Inicia o processo de arrastar o manipulador de progresso do vídeo
  const startDrag = (event, index) => {
    isDragging.value = true;
    draggingIndex = index;


    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('touchmove', handleDrag, { passive: false });
    document.addEventListener('touchend', stopDrag);
  };

  // Finaliza o processo de arraste do manipulador de progresso
  const stopDrag = () => {
    if (!isDragging.value) return;
    isDragging.value = false;

    const videoEl = document.querySelectorAll('.responsive-video.preview')[draggingIndex];
    if (wasPlaying) videoEl.play();
    draggingIndex = null;

    document.removeEventListener('mousemove', handleDrag);
    document.removeEventListener('mouseup', stopDrag);
    document.removeEventListener('touchmove', handleDrag);
    document.removeEventListener('touchend', stopDrag);
  };

  // Atualiza o tempo do vídeo e a posição do manipulador enquanto é arrastado
  const handleDrag = (event) => {
    if (!isDragging.value || draggingIndex === null) return;

    const videoEl = document.querySelectorAll('.responsive-video.preview')[draggingIndex];
    const progressBar = document.querySelectorAll('.custom-progress-bar')[draggingIndex];
    const rect = progressBar.getBoundingClientRect();
    const clientX = event.type.startsWith('touch') ? event.touches[0].clientX : event.clientX;
    const fraction = Math.min(Math.max((clientX - rect.left) / rect.width, 0), 1);

    videoEl.currentTime = fraction * videoEl.duration;

    
    document.querySelectorAll('.custom-progress-handle')[draggingIndex].style.left = `${fraction * 100}%`;
  };



  const DeixaEmTelaCheia = (index) => {
    console.log('Iniciando DeixaEmTelaCheia para o vídeo:', index);
    const videoWrapper = document.querySelectorAll('.video-wrapper')[index];
  
    if (!videoWrapper) {
      console.error('Erro: videoWrapper não encontrado para index:', index);
      return;
    }
  
    if (!document.fullscreenElement) {
      videoWrapper.requestFullscreen().then(() => {
        isFullscreen.value[index] = true;
        if (screen.orientation && screen.orientation.lock) {
          screen.orientation.lock('landscape').then(() => {
            console.log('Orientação bloqueada para paisagem.');
          }).catch((err) => {
            console.warn(`Falha ao bloquear orientação: ${err.message}`);
          });
        }
      }).catch((err) => {
        console.error(`Erro ao entrar em tela cheia: ${err.message}`);
      });
    } else {
      
      document.exitFullscreen().then(() => {
        isFullscreen.value[index] = false;
        if (screen.orientation && screen.orientation.unlock) {
          screen.orientation.unlock();
          console.log('Orientação desbloqueada.');
        }
      }).catch((err) => {
        console.error(`Erro ao sair da tela cheia: ${err.message}`);
      });
    }
  
    console.log('Chamando PeparaParaPular...');
    PeparaParaPular(index);
  };
  

  const PeparaParaPular = (index) => {
    const videoElements = document.querySelectorAll('.responsive-video.preview');
  
    // Verifica se o índice é válido
    if (!videoElements || index < 0 || index >= videoElements.length) {
      console.warn(`Vídeo não encontrado no índice ${index}`);
      return; // Sai da função se o índice não for válido
    }
  
    const videoElement = videoElements[index];
    console.log("A função PeparaParaPular foi ativada para", videoElement);
  
    // Remove listeners existentes para evitar múltiplos registros
    videoElement.removeEventListener('touchstart', handleTouchStart);
    videoElement.removeEventListener('touchmove', handleTouchMove);
    videoElement.removeEventListener('touchend', handleTouchEnd);
  
    // Adiciona os listeners novamente
    videoElement.addEventListener('touchstart', handleTouchStart);
    videoElement.addEventListener('touchmove', handleTouchMove);
    videoElement.addEventListener('touchend', () => handleTouchEnd(index));
  };

      // Para o double click
  const togglePlayPause = (index) => {
    const videoElements = document.querySelectorAll('.responsive-video.preview');
    const videoEl = videoElements[index];

    if (videoEl.paused) {
      videoEl.play();
    } else {
      videoEl.pause();
    }  
  };   

  let touchStartTime = null; // Armazena o momento do touchstart


  const handleTouchStart = (event) => {
    touchStartX.value = event.touches[0].clientX; // Captura a posição inicial do toque
    touchStartTime = Date.now(); // Registra o momento em que o toque começou
  };
  
  
  const handleTouchMove = (event) => {
    touchEndX.value = event.touches[0].clientX; // Atualiza a posição enquanto o toque se move
    //console.log("touchEndX.value" , touchEndX.value)
  };
  
  const handleTouchEnd = (index) => {
    console.log("Iniciando handleTouchEnd");

    let touchDuration = 0;

    if (touchStartTime !== null) {
      touchDuration = Date.now() - touchStartTime;
      console.log(`Duração do toque: ${touchDuration}ms`);
    }
  
    if (touchStartX.value === null || touchEndX.value === null) return;
  
    const diffX = touchStartX.value - touchEndX.value;
  
    // Define o limite para considerar um gesto válido
    const swipeThreshold = 50;
  
    if (Math.abs(diffX) > swipeThreshold) {

      if (touchDuration > 700){

        touchStartX.value = null;
        touchEndX.value = null;
      
        const nextIndex = index + 1; // Próximo índice
        const videoElements = document.querySelectorAll('.responsive-video.preview');
      
        if (videoElements && nextIndex < videoElements.length) {
          PeparaParaPular(nextIndex);
        } else {
          console.warn(`Não há próximo vídeo no índice ${nextIndex}`);
        }
        
        if (diffX > 0) {          
          // Deslizou para a esquerda (próximo vídeo)
              // Resetar valores após o toque

          goToNextVideo(index);
        } else {
          // Deslizou para a direita (vídeo anterior)
          goToPreviousVideo(index);
        }
      }else{
        console.log()
      }
    }  
    console.log("Finalizando handleTouchEnd");
  };
  

  const goToNextVideo = async (index) => {
    console.log("Iniciando goToNextVideo !!!!!!");

    // Pausa e reseta o vídeo atual
    const currentVideoEl = document.querySelectorAll('.responsive-video.preview')[index];


    if (currentVideoEl) {
        currentVideoEl.pause();
        currentVideoEl.currentTime = 0;
    }

    const nextIndex = index + 1;
    const nextVideoEl = document.querySelectorAll('.responsive-video.preview')[nextIndex];

    let numberLastVideoAvailable = document.querySelectorAll('.responsive-video.preview').length
    let positionActualVideo = nextIndex + 1

    console.log("positionActualVideo " , positionActualVideo)

    console.log("numberLastVideoAvailable " , numberLastVideoAvailable)

    if (positionActualVideo === numberLastVideoAvailable){
      console.log("Chegou no último vídeo, carregando mais...");
      loadNextPage(); 

    }



    if (nextVideoEl) {
      // Role a página até o próximo vídeo (se necessário)
      const nextVideoContainer = document.querySelectorAll('.Feeds-user-container')[nextIndex];
      nextVideoContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });

      // Toque o próximo vídeo
      nextVideoEl.play();

      // Ajusta o modo de tela cheia para o próximo vídeo, se necessário
      if (document.fullscreenElement) {
          document.exitFullscreen().then(() => {
              const nextVideoWrapper = document.querySelectorAll('.video-wrapper')[nextIndex];
              console.log("nextVideoWrapper ", nextVideoWrapper)
              nextVideoWrapper.requestFullscreen();
          });
      }
    }    
};


  
  const goToPreviousVideo = (index) => {
    console.log("Iniciando goToPreviousVideo !!!!!!");

    // Pausa e reseta o vídeo atual
    const currentVideoEl = document.querySelectorAll('.responsive-video.preview')[index];

    if (currentVideoEl) {
        currentVideoEl.pause();
        currentVideoEl.currentTime = 0;
    }
    const nextIndex = index + -1;

    console.log("nextIndex " , nextIndex)

    const nextVideoEl = document.querySelectorAll('.responsive-video.preview')[nextIndex];

    console.log("nextVideoEl " , nextVideoEl)

    if (nextVideoEl) {
      // Role a página até o próximo vídeo (se necessário)
      const nextVideoContainer = document.querySelectorAll('.Feeds-user-container')[nextIndex];
      nextVideoContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });

      // Toque o próximo vídeo
      nextVideoEl.play();

      // Ajusta o modo de tela cheia para o próximo vídeo, se necessário
      if (document.fullscreenElement) {
          document.exitFullscreen().then(() => {
              const nextVideoWrapper = document.querySelectorAll('.video-wrapper')[nextIndex];
              console.log("nextVideoWrapper ", nextVideoWrapper)
              nextVideoWrapper.requestFullscreen();
          });
      }
    }  
  };

  const callFetchUserData = () => {
    if (typeof fetchUserData === 'function') {
      fetchUserData();
    } else {
      console.warn("fetchUserData não foi passado ou não é uma função!");
    }
  };
  
  
  return {
    isMuted,
    toggleMute,
    updateProgress,
    seekVideo,
    startDrag,
    stopDrag,
    handleDrag,
    isFullscreen,
    DeixaEmTelaCheia,
    callFetchUserData,
    togglePlayPause,
    
  };
}
