
<template>
  <div class="Base-Div">
    <div class="profile-user-info">
      <img
        :src="userProfilePicture"
        alt="User Picture"
        class="user-round-picture"
      />
      <div class="profile-name">{{ userProfileName }}</div>
    </div>
    <div class="profile-Hash-container">
        <div class="profile-Hash-border">
          <div
            v-for="(hashtag, index) in activeHashtags"
            :key="index"
            class="hashtag-item"
            @click="filterByHashtag(hashtag.text)"
            :class="{ 
              'selected-hashtag': Array.isArray(selectedHashtags) && selectedHashtags.includes(hashtag.text),
              'disabled-hashtag': !isHashtagAvailable(hashtag.text)
            }"
          >
              <span class="profile-hash-item">{{ hashtag.text }}</span>
          </div>
        </div>
      </div>  
    <div
      class="video-user-container"
      v-for="(video, index) in filteredVideos"
      :key="index"
      :data-embed-url="video.embedURL"
      :href="`#${index}`"
    >
      <div class="video-title-a">
        <h3>{{ video.videoTitle }}</h3>
      </div>

      <div class="video-wrapper">
        <!-- Preview Video -->
        <video
           :src="video.previewURL"
          class="responsive-video preview"
          preload="auto"
          muted          
          loop
          @click="togglePlayPause(index)"
          @dblclick="DeixaEmTelaCheia(index)"
          @timeupdate="updateProgress(index)"
          @contextmenu.prevent
        ></video>

        <button @click="toggleMute(index)" class="mute-button">
          <i :class="isMuted[index] ? 'fas fa-volume-mute' : 'fas fa-volume-up'"></i>
        </button>

        <button @click="DeixaEmTelaCheia(index)" class="fullscreen-button">
          <i :class="isFullscreen[index] ? 'fas fa-compress' : 'fas fa-expand'"></i>
        </button>
        
        <div class="custom-progress-bar" @click="seekVideo($event, index)">
          <div class="custom-progress-handle" 
          @mousedown="startDrag($event, index)" 
          @touchstart="startDrag($event, index)">
          </div>
        </div>


      </div>
      
      <div class="user-buttons-and-tags">

        <div class="interaction-buttons">
            <button class="thumbs-up" @click="toggleThumbsUp(index)">
              <i :class="{'fas fa-thumbs-up': true, active: isThumbsUpActive(index)}"></i>
            </button>
            <button class="thumbs-down" @click="toggleThumbsDown(index, userVideos, setupIntersectionObserver)">
              <i class="fas fa-thumbs-down"></i>
            </button>
            <div
              :class="{
                'thumbs-up-text': thumbsUpCounts[index] === 1,
                'neutral-text': thumbsUpCounts[index] === 0,
              }"
            >
            </div>
        </div>
        <div class="video-hashtags-container">
          <div class="video-hashtags-border">
            <span
              v-for="(hashtag, idx) in video.hashtags"
              :key="idx"
              class="video-hashtag-item"
                      >
              {{ hashtag }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted, nextTick, computed } from 'vue';
import { useThumbs } from '../composables/useThumbs';
import { customPlayerFunctions } from '../composables/customPlayer.js';


const BASE_URL = 'https://strapi.vulcano2k.com';

export default {
  name: 'UserProfile',
  props: ['id'],
  setup(props) {
    const userVideos = ref([]);
    const videoRef = ref(null);
    const userProfilePicture = ref('');
    const userProfileName = ref('');
    const activeHashtags = ref([]);
    const selectedHashtags = ref([]);
    const selectedHashtag = ref('');
    const { thumbsUpCounts, toggleThumbsUp, isThumbsUpActive, toggleThumbsDown } = useThumbs();



    // Lifecycle hook that runs when the component is mounted
    onMounted(() => {
      setupIntersectionObserver();
      fetchUserData();
    });

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/user-profiles`, {
          params: {
            filters: {
              ProfileName: {
                $eq: props.id,
              },
            },
            populate: {
              user_video_embeds: {
                populate: {
                  hashtags: true,
                  VideoPreview: true,
                  VideoTitle: true,
                },
              },
              ProfilePicture: true,
            },
          },
        });

        const profiles = response.data.data;

        if (profiles.length > 0) {
          const userProfile = profiles[0];
          userProfileName.value = userProfile.attributes?.ProfileName || '';
          userProfilePicture.value = getProfilePicture(userProfile) || '';

          userVideos.value = userProfile.attributes.user_video_embeds.data.map(
            (videoItem) => ({
              hashtags: getHashtags(videoItem),
              previewURL: getVideoPreviewURL(videoItem),
              videoTitle: videoItem.attributes?.VideoTitle || 'Untitled',
            })
          ).sort(() => Math.random() - 0.5); // Shuffle videos
          
          thumbsUpCounts.value = userVideos.value.map(() => 0);

          // Configure hashtags separately
          configureHashtags(userVideos.value);
          await nextTick();
          setupIntersectionObserver();
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const configureHashtags = (videos) => {
      const allHashtags = videos.flatMap((video) => video.hashtags);
      const uniqueHashtags = [...new Set(allHashtags)];
      // Ordena as hashtags em ordem alfabética
      const sortedHashtags = uniqueHashtags.sort((a, b) => a.localeCompare(b));

      activeHashtags.value = sortedHashtags.map((tag) => ({
        text: tag,
        url: `https://www.vulcano2k.com/hashtag/${encodeURIComponent(tag)}`,
      }));
    };
 
    const getProfilePicture = (userProfile) => {
      const profilePictureData =
        userProfile.attributes?.ProfilePicture?.data?.attributes;
      if (profilePictureData?.url) {

        return `${BASE_URL}${profilePictureData.url}`;
      }
      return '';
    };

    const getHashtags = (videoItem) => {
      return (
        videoItem.attributes?.hashtags?.data.map(
          (hashtag) => hashtag.attributes.HashCategorie
        ) || []
      );
    };

    const getVideoPreviewURL = (item) => {
      const videoPreviewData = item.attributes?.VideoPreview?.data;

      if (
        videoPreviewData &&
        Array.isArray(videoPreviewData) &&
        videoPreviewData.length > 0
      ) {
        const previewAttributes = videoPreviewData[0].attributes;
        const previewUrl = previewAttributes?.url;
        return `${BASE_URL}${previewUrl}`;
      }
      return null;
    };

    const filteredVideos = computed(() => {
      if (selectedHashtags.value.length === 0) {
        // Nenhuma hashtag selecionada, retorna todos os vídeos
        return userVideos.value;
      } else {
        // Retorna vídeos que possuem todas as hashtags selecionadas
        return userVideos.value.filter(video =>
          selectedHashtags.value.every(selected => video.hashtags.includes(selected))
        );
      }
    });

    const availableHashtags = computed(() => {
      // Extrai hashtags dos vídeos atualmente filtrados
      const filteredHashtags = filteredVideos.value.flatMap(video => video.hashtags);
      return [...new Set(filteredHashtags)]; // Remove duplicatas
    });

    const isHashtagAvailable = (hashtag) => {
      return availableHashtags.value.includes(hashtag);
    };

    const filterByHashtag = (hashtag) => {
      if (!isHashtagAvailable(hashtag)) {
        return; // Ignora hashtags indisponíveis
      }

      const index = selectedHashtags.value.indexOf(hashtag);
      if (index === -1) {
        // Adiciona a hashtag se não estiver na lista
        selectedHashtags.value.push(hashtag);
      } else {
        // Remove a hashtag se já estiver na lista
        selectedHashtags.value.splice(index, 1);
      }

      // Aguarda a atualização do DOM e reaplica os observadores
      nextTick(() => {
        setupIntersectionObserver();
      });
    };

  let observer;

  const setupIntersectionObserver = () => {
      // Observer for preview videos
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const video = entry.target;
            if (entry.isIntersecting) {
              // If the video is in the viewport
              console.log(2222)
              if (video.paused) {                
                video.play(); // Play the video
              }
            } else {
              // If the video is out of the viewport
              video.pause(); // Pause the video
              video.currentTime = 0; // Reset to start
            }
          });
        },
        {
          threshold: [0], // Trigger when any part of the element is visible
        }
      );
      // Observe all preview videos
      document.querySelectorAll('.responsive-video.preview').forEach((video) => {
        observer.observe(video);
      });
    };


    const { 
      isMuted, 
      toggleMute, 
      updateProgress, 
      seekVideo, 
      startDrag, 
      stopDrag, 
      handleDrag,
      isFullscreen,
      DeixaEmTelaCheia,
      togglePlayPause,      
    } = customPlayerFunctions(fetchUserData);

    return {
      userVideos,
      thumbsUpCounts,
      toggleThumbsUp,
      toggleThumbsDown,      
      isThumbsUpActive,
      videoRef,
      activeHashtags,
      userProfilePicture,
      userProfileName,
      filterByHashtag,
      filteredVideos,
      selectedHashtag,
      selectedHashtags,
      isHashtagAvailable,
      isMuted,
      toggleMute,
      updateProgress,
      seekVideo,
      startDrag,
      stopDrag,
      handleDrag,
      isFullscreen,
      DeixaEmTelaCheia,
      togglePlayPause
    };
  },
};
</script>

<style>

/* ----------------------AJUSTE CABECALHO---------------------------------- */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.main-content {
  padding: 0 !important;
  margin: 0;
}

.Base-Div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
}

.user-name-hashtags {
  flex-grow: 1;
}

.profile-user-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  padding-bottom: 20px;
}

.user-round-picture {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 50%;
}

.profile-name {
  font-size: 50px;
}

.profile-Hash-container {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-right: 40px;
  border-radius: 15px  
}

.profile-Hash-border {
  display: flex;
  flex-wrap: wrap;
  padding : 10px;
  padding-top: 32px;
  gap: 10px;
  position: relative;
  border-radius: 15px
}

.profile-Hash-border::before {
  content: 'Filters';
  position: absolute;
  top: -18px; /* Adjust this value to fine-tune the vertical position */
  left: 10px; /* Adjust to add some padding from the left */
  padding: 0 10px;
  background-color: rgb(0, 0, 0); /* Matches the background color to create the gap effect */
  font-size: 28px;
  color: #a90606;
  border-radius: 15px
  
}

.hashtag-item {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.profile-hash-item {
  text-decoration: none;
  background-color: #333;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
}

.profile-hash-item a {
  color: white;
  text-decoration: none;
}

.remove-hashtag-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.remove-hashtag-btn:hover {
  color: red;
}

/* Cursor pointer for hashtag items */
.hashtag-item {
  cursor: pointer;
}

/* Novo CSS para a hashtag selecionada */
.hashtag-item.selected-hashtag .profile-hash-item {
  background-color: #b11919; /* Cor de fundo para a tag selecionada */
  color: white; /* Cor do texto para a tag selecionada */
}

/* ----------------------AJUSTE VIDEOS---------------------------------- */

.video-title-a {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 80%;
  text-align: left;
  font-family: 'Arial', sans-serif;
  font-size: 32px;
  color: #696969;
  margin: 10px 0;

}

.video-title-a h3 {
  margin: 0;
  font-weight: bold;
}

.video-user-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo (hashtags e vídeo) */
  width: 100%;
}

.video-wrapper {
  width: 100%; /* Garantir que ocupe 100% da largura disponível */
  max-width: 80%; /* Ajustar para corresponder ao tamanho máximo do vídeo */
  aspect-ratio: 16/9; /* Manter a proporção do vídeo */
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* Inclui padding e borda no cálculo */
  overflow: hidden; /* Certifica que nenhum elemento extra vaze do contêiner */
  position: relative; /* Mantém os elementos posicionados corretamente */
}

.responsive-video.preview {
  width: 100%; /* Ocupa todo o espaço disponível dentro do contêiner */
  height: auto; /* Mantém a proporção original do vídeo */
  object-fit: cover; /* Garante que o vídeo preencha completamente o espaço */
}



.user-buttons-and-tags{
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content:left;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 32px;
}

.interaction-buttons {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0;  
  max-height: 80%;
}

.thumbs-up,
.thumbs-down {
  display: flex;
    justify-content: flex-start;
    font-size: 18px;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.thumbs-up-text {
  color: rgb(133, 44, 155);
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 1.5em;
}

.thumbs-down-text {
  color: rgb(77, 77, 77);
  font-weight: bold;
  font-family: 'Verdana', sans-serif;
  font-size: 1.5em;
}

.thumbs-up:active,
.thumbs-down:active {
  background-color: white;
  color: black;
}

.video-hashtags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  width: auto;
  border: 1px solid #ccc; /* Linha fina e cinza */
  box-sizing: border-box; /* Inclui o padding e a borda no cálculo da largura */
  flex-grow: 1;
}

.video-hashtags-border {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens ocupem múltiplas linhas */
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 10px;
  
}

.video-hashtag-item {
  text-decoration: none;
  background-color: #333;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;  
}

.video-hashtag-item a {
  color: white;
  text-decoration: none;
}

.hashtag-item.disabled-hashtag .profile-hash-item {
  background-color: #999; /* Cor para indicar indisponibilidade */
  cursor: not-allowed; /* Cursor que indica que não é clicável */
  pointer-events: none; /* Impede cliques */
}

.fas.fa-thumbs-up.active {
  color: rgb(0, 124, 182);
}

.fas.fa-thumbs-down.active {
  color: red;
}

.mute-button {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
}

.mute-button:hover {
  background: rgba(0, 0, 0, 0.8);
}



.fullscreen-button {
  position: absolute;
  bottom: 10px; 
  right: 10px; 
  background: rgba(0, 0, 0, 0.5);
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  z-index: 10;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.fullscreen-button:hover {
  background: rgba(0, 0, 0, 0.8);
}



/* media-------------------------------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 431px) {

  .user-round-picture {
    width: 75px;
    height: 75px;
  }

  .profile-name {
    font-size: 32px;
  }

  .profile-Hash-border {
    gap: 10px
  }

  .profile-hash-item {
    font-size: 14px;
  }

  .video-user-container {
    width: 100%;
    box-sizing: border-box;
  }

  .video-wrapper {
    width: 100%; /* Mantém o vídeo com 80% de largura */
    max-width: 100%;
  }

  .responsive-video {
    width: 100%;
    max-width: 100%;
  } 

  .video-title-a {
  font-size: 18px;
  max-width: 100%;
  }

  .user-buttons-and-tags{
  width: 100%;
  }

  .video-hashtags-container {
    max-width: 100%;
  }

  .video-hashtags-border {
    display: flex;
  }

  .video-hashtag-item{
    font-size: 14px;
  }
}
</style>