import { ref  } from 'vue';

export function useThumbs() {
  const thumbsUpCounts = ref([]);
  let observer;

  const observeVideos = () => {
    // Antes de observar, desconecta para não acumular listeners velhos
    if (observer) {
      observer.disconnect();
    }
    // Começa a observar os vídeos
    document.querySelectorAll('.profile-responsive-video.preview').forEach((video) => {
      observer.observe(video);
    });
  };

  const toggleThumbsUp = (index) => {
    thumbsUpCounts.value[index] = thumbsUpCounts.value[index] === 0 ? 1 : 0;
  };

  const isThumbsUpActive = (index) => {
    return thumbsUpCounts.value[index] === 1;
  };

  const toggleThumbsDown = (index, userVideos, onUpdate) => {
    const videoContainer = document.querySelectorAll('.video-user-container')[index];
    if (videoContainer) {
      setTimeout(() => {
        // Remove o vídeo e o count
        userVideos.splice(index, 1);
        thumbsUpCounts.value.splice(index, 1);
        
  
        // Chama o callback para atualizar os observers
        if (onUpdate && typeof onUpdate === 'function') {
          onUpdate();
        }
      }, 100);
    }
  };

  return {
    thumbsUpCounts,
    toggleThumbsUp,
    isThumbsUpActive,
    toggleThumbsDown,
    
    observeVideos,
  };
}
